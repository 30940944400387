import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "../components/atom/Typography";
import Logo from "../assets/logo.png";
import playstore from "../assets/playstore.png";
import appstore from "../assets/appstore.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function Copyright() {
  return (
    <React.Fragment>
      {"© "}
      {/* <Link color="inherit"> */}
      Langmate Inc., All Rights Reserved { " "}
      {/* </Link>{' '} */}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

export default function AppFooter() {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Typography component="footer" sx={{ display: "flex", bgcolor: "#e2f1f8" }}>
      <Container sx={{ my: isSmallDevice ? 2 : 8, display: "flex" }}>
        <Grid container spacing={isSmallDevice ? 2 : 5}>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Box component="ul" sx={{ m: 0, listStyle: "none", p: 0 }}>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link href="/">Home</Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link href="/tutorial">チュートリアル</Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link href="https://twitter.com/Hatenya_QuizApp">Twitter</Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link href="/inquiry">お問い合わせ</Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Link href="/privacy-policy" style={{ textDecoration: "none" }}>
              <Typography variant="h6" gutterBottom>
              プライバシーポリシー
              </Typography>
            </Link>
            {isSmallDevice && (
              <Link href="/terms-of-use" style={{ textDecoration: "none" }}>
                <Typography variant="h6" gutterBottom>
                利用規約
                </Typography>
              </Link>
            )}
            <Link href="https://play.google.com/store/apps/details?id=com.jbcoder.hatenya">
              <Box
                component="img"
                src={playstore}
                alt="suitcase"
                sx={{ height: 55 }}
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
          {!isSmallDevice && (
              <Link href="/terms-of-use" style={{ textDecoration: "none" }}>
                <Typography variant="h6" gutterBottom>
                利用規約
                </Typography>
              </Link>
            )}
            <Link href="https://apps.apple.com/us/app/%E3%81%AF%E3%81%A6%E3%81%AB%E3%82%83-%E4%B8%96%E7%95%8C%E4%B8%AD%E3%81%AE%E7%8C%AB%E3%82%92%E9%9B%86%E3%82%81%E3%82%8B%E9%9B%91%E5%AD%A6%E3%82%AF%E3%82%A4%E3%82%BA%E3%82%B2%E3%83%BC%E3%83%A0%E3%82%A2%E3%83%97%E3%83%AA/id1671360928">
              <Box
                component="img"
                src={appstore}
                alt="suitcase"
                sx={{ height: 55 }}
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              spacing={2}
              sx={{ height: 120 }}
            >
              <Grid item sx={{ display: "flex" }}>
                <Box
                  component="img"
                  src={Logo}
                  alt="graph"
                  sx={{ height: 40 }}
                />
              </Grid>
              <Grid item>
                <Copyright />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
