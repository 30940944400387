import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../atom/Typography";
import SignIn from "../../assets/catroom1.png";
import Swheel from "../../assets/blueBg.png";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  px: 5,
};

function CatHouse() {
  const theme = useTheme()
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <>
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: '#5F1AC4', overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 5,
          mb: 5,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <div>
          <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={4}>
            <Box
                component="img"
                src={SignIn}
                alt="graph"
                sx={{ maxHeight: 400 }}

                //sx={{ height: 400,width:300 }}
              />
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
            <Box sx={item}>
                <Typography variant="h4" style={{color:'white'}} align="flex-start">
                Wheelを回して猫を獲得しよう！
                </Typography>
                <Typography
                  variant="h5"
                  align="flex-start"
                  sx={{ mt: 2, mb: 2 }}
                  style={{color:'white'}}
                >
                 猫はノーマル、レア、スーパーレア、ウルトラレア、ハイパーレアまでランク分けされています。
195匹、全ての猫をゲットしてみよう
                </Typography>
              </Box>
            </Grid>

          </Grid>
        </div>
      </Container>
    </Box>
    <Box component="section" sx={{ display: "flex", overflow: "hidden" }}>
        <img
          alt="img"
          src={Swheel} //generateBannerImageUrl(content.bongoId, etag, screen)
          style={{
            width: "100%",
            height: isSmallDevice ?"30px": "60px",
            padding: "0px",
            margin: "0px",
          }}
        />
      </Box>
    </>
  );
}

export default CatHouse;
