import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import DayNightImg from "../../assets/DayNight.png";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const imageWeb = {
  height: 500,
  //width: 600
};
const imageMob = {
  height: 300,
  //width: 350
};


function DayNight() {
  const theme = useTheme()
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: '#F3F3F3', overflow: "hidden" }}
    >
      <Container
        sx={{
          // mt: 5,
          // mb: 5,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div>
          <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={4}>
            <Box
                component="img"
                src={DayNightImg}
                alt="graph"
                sx={isSmallDevice ? imageMob :imageWeb}
              />
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  );
}

export default DayNight;
