import * as React from "react";

import Box from "@mui/material/Box";

import DayNightImg from "../../assets/Buy.png";

function BuyCoin() {
  return (
    <Box
    component="section"
    sx={{ display: 'flex', overflow: 'hidden' }}
  >
    <img
    alt="img"
    src={DayNightImg} //generateBannerImageUrl(content.bongoId, etag, screen)
    style={{ width: '100%', maxHeight: '950px',padding:'0px',margin:'0px' }}
  />
  </Box>
  );
}

export default BuyCoin;