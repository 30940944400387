import './App.css';
import ResponsiveAppBar from './components/AppBar'
import withRoot from './components/withRoot';
import Home from './components/Home'
import AppFooter from './components/AppFooter'
import Privacy from './components/Privacy'
import Terms from './components/Terms'
import About from './components/molecules/About'
import Contact from './components/Contact'

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
function App() {
  return (
    <>
    <ResponsiveAppBar />
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/terms-of-use" element={<Terms />} />
      <Route path="/tutorial" element={<About />} />
      <Route path="/inquiry" element={<Contact />} />

    </Routes>
  </BrowserRouter>
  <AppFooter />
  </>
  );
}
export default withRoot(App);