import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import YouTube from 'react-youtube';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  px: 5,
};

function About() {
  const theme = useTheme()
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
  const onPlayerReady = (event)=> {
    event.target.pauseVideo();
  }

  const opts = {
    height: '490',
    width: '800',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  const optsMobile = {
    height: '240',
    width: '350',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  return (
    <Box
      component="section"
      style={{background:'linear-gradient(180deg, #4EAC34 35.21%, #6DB44A 100%)'}}
      sx={{ display: 'flex', overflow: 'hidden' }}
    >
      <Container
        sx={{
          mt: 5,
          mb: 5,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: -180,
            opacity: 0.7,
          }}
        />

        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <Box sx={item}>
              <YouTube videoId="r5oyFGBMOcI" opts={isSmallDevice? optsMobile : opts}  onReady={onPlayerReady} />

              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  );
}

export default About;