import * as React from 'react';
import ProductHero from './molecules/ProductHero'
import Testomonial from './molecules/Testomonial'
import About from './molecules/About'
import DailyCoin from './molecules/DailyCoin'
import DayNight from './molecules/DayNight'
import BuyCoin from './molecules/BuyCoin'
import CatHouse from './molecules/CatHouse'
import QuizPage from './molecules/QuizPage'
import WheelPage from './molecules/WheelPage'
function Home() {
  return (
    <React.Fragment>
        <ProductHero />
        <About />
        <Testomonial />
        <DailyCoin />
        <DayNight />
        <QuizPage />
        <WheelPage />
        <CatHouse />
        <BuyCoin />
    </React.Fragment>
  );
}

export default Home;