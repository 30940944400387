import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../atom/Typography";
import SignIn from "../../assets/SignIn.png";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  px: 5,
};

function Testomonial() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: '#6DB44A', overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 5,
          mb: 5,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <div>
          <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={4}>
            <Box
                component="img"
                src={SignIn}
                alt="graph"
                 sx={{ maxHeight: 400 }}
                // sx={{ height: 400,width:300 }}
              />
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
            <Box sx={item}>
                <Typography variant="h4" align="flex-start">
                無料で遊べる猫集めクイズゲーム
                </Typography>
                <Typography
                  variant="h5"
                  align="flex-start"
                  sx={{ mt: 2, mb: 2 }}
                >
                 世界中に実際にいる195種類の猫を、約20,000問の雑学クイズに挑戦して集めよう。
Apple storeとGoogle Playからダウンロードできます。
                </Typography>
              </Box>
            </Grid>

          </Grid>
        </div>
      </Container>
    </Box>
  );
}

export default Testomonial;
