import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import Button from "@mui/material/Button";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TwitterIcon from '@mui/icons-material/Twitter';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import Logo from "../assets/logo.png";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HomeIcon from '@mui/icons-material/Home';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import GavelIcon from '@mui/icons-material/Gavel';
const ResponsiveAppBar = () => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  console.log("isSmallDevice", isSmallDevice);

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
      <Link
          href="/"
          sx={{ textDecoration: "none" }}
        >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItemButton>
        </ListItem>
        </Link>
        <Link
          href="tutorial"
          sx={{ textDecoration: "none" }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <CoPresentIcon />
              </ListItemIcon>
              <ListItemText primary={"チュートリアル"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link
          href="https://twitter.com/Hatenya_QuizApp"
          sx={{ textDecoration: "none" }}
        >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <TwitterIcon />
            </ListItemIcon>
            <ListItemText primary={"Twitter"} />
          </ListItemButton>
        </ListItem>
        </Link>
        <Link
          href="/privacy-policy"
          sx={{ textDecoration: "none" }}
        >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <PrivacyTipIcon />
            </ListItemIcon>
            <ListItemText primary={"プライバシーポリシー"} />
          </ListItemButton>
        </ListItem>
        </Link>
        <Link
          href="/terms-of-use"
          sx={{ textDecoration: "none" }}
        >
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <GavelIcon />
            </ListItemIcon>
            <ListItemText primary={"利用規約"} />
          </ListItemButton>
        </ListItem>
        </Link>
        <Link
          href="/inquiry"
          sx={{ textDecoration: "none" }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <QuestionAnswerIcon />
              </ListItemIcon>
              <ListItemText primary={"お問い合わせ"} />
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
    </Box>
  );
  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}`,}}
    >
      <Container maxWidth={isSmallDevice ? "xl" : "lg"} style={{paddingLeft:'0px' }}>
        <Toolbar sx={{ flexWrap: "wrap" }}>
          {isSmallDevice && (
            <React.Fragment>
              <MenuIcon
                onClick={toggleDrawer("left", true)}
                style={{ paddingRight: "6px", width: "30px" }}
              />
              <Drawer
                anchor={"left"}
                open={state["left"]}
                onClose={toggleDrawer("left", false)}
              >
                {list("left")}
              </Drawer>
            </React.Fragment>
          )}
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            <Link href="/">
              <Box component="img" src={Logo} alt="graph" sx={{ height: 40 }} />
            </Link>
          </Typography>
          {!isSmallDevice && (
            <h2>
              <nav>
                <Link
                  href="/"
                  variant="button"
                  color="text.primary"
                  sx={{ my: 1, mx: 1.5, textDecoration: "none" }}
                >
                  Home
                </Link>
                <Link
                  href="/tutorial"
                  variant="button"
                  color="text.primary"
                  sx={{ my: 1, mx: 1.5, textDecoration: "none" }}
                >
                  チュートリアル
                </Link>
                {/* <>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    Twitter
                    <ArrowDropDownIcon />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <Link
                      href="/privacy-policy"
                      style={{ textDecoration: "none" }}
                    >
                      <MenuItem onClick={handleClose}>プライバシーポリシー</MenuItem>
                    </Link>
                    <Link
                      href="/terms-of-use"
                      style={{ textDecoration: "none" }}
                    >
                      <MenuItem onClick={handleClose}>
                      利用規約
                      </MenuItem>
                    </Link>
                  </Menu>
                </> */}
                                <Link
                  href="https://twitter.com/Hatenya_QuizApp"
                  variant="button"
                  color="text.primary"
                  sx={{ my: 1, mx: 1.5, textDecoration: "none",textTransform:'none' }}
                >
                  Twitter
                </Link>
                <Link
                  href="/inquiry"
                  variant="button"
                  color="text.primary"
                  sx={{ my: 1, mx: 1.5, textDecoration: "none" }}
                >
                  お問い合わせ
                </Link>
              </nav>
            </h2>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
