import React from "react";
import ProductHeroLayout from "./ProductHeroLayout";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "../atom/Typography";
import Container from "@mui/material/Container";
import SignIn from "../../assets/playQuiz.png";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// const backgroundImage =
//   'https://image.shutterstock.com/image-vector/programming-code-coding-hacker-background-260nw-1714491562.jpg';
import backgroundImage from "../../assets/quiz_background.png";
const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  px: 5,
};
const imageWeb = {
  height: 500,
 // width: 370
};
const imageMob = {
  height: 350,
//  width: 250
};

export default function ProductHero() {
  const theme = useTheme()
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: isSmallDevice? "#32613d" : "#F3F3F3", // Average color of the background image.
        backgroundPosition: "center",
        height:'100%'
      }}
    >
      <div>
      <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 5,
          mb: 5,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <div>
          <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={4}>
            <Box
                component="img"
                src={SignIn}
                alt="graph"
                sx={isSmallDevice ? imageMob :imageWeb}
              />
            </Grid>
            <Grid item xs={12} md={8} lg={8}>
            <Box sx={item}>
                <Typography variant="h4" align="flex-start">
                さあ、雑学クイズにチャレンジしてください！
                </Typography>
                <Typography
                  variant="h5"
                  align="flex-start"
                  sx={{ mt: 2, mb: 2,color:'black' }}
                >
                 クイズのジャンルは漫画、芸能、スポーツ、グルメ、歴史、ビジネスなど70以上のジャンルで、20,000問以上の問題が出題されます。
4択の中から正解を選択してください。
5問連続で正解するとボーナスコインをゲットできます！
                </Typography>
              </Box>
            </Grid>

          </Grid>
        </div>
      </Container>
    </Box>
      </div>
    </ProductHeroLayout>
  );
}

