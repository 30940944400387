import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../atom/Typography";
import SignIn from "../../assets/wheelScreen.png";
import Swheel from "../../assets/SuperWheel.png";
import nWheel from "../../assets/normalwheel.png";
import supWheel from "../../assets/supWheel.png";

//import sWheel from "../../assets/SuperWheel.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  px: 5,
};

function Testomonial() {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {!isSmallDevice && (
        <Box component="section" sx={{ display: "flex", overflow: "hidden" }}>
          <img
            alt="img"
            src={Swheel} //generateBannerImageUrl(content.bongoId, etag, screen)
            style={{
              width: "100%",
              height: "140px",
              padding: "0px",
              margin: "0px",
            }}
          />
        </Box>
      )}

      <Box
        component="section"
        sx={{ display: "flex", bgcolor: "#F3F3F3", overflow: "hidden" }}
      >
        <Container
          sx={{
            mt: 5,
            mb: 5,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src="/static/themes/onepirate/productCurvyLines.png"
            alt="curvy lines"
            sx={{
              pointerEvents: "none",
              position: "absolute",
              top: -180,
              opacity: 0.7,
            }}
          />
          <div>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={6}>
                <Box sx={item}>
                  {isSmallDevice && (
                    <Box
                      component="img"
                      src={supWheel}
                      alt="graph"
                      sx={{
                        height: 200,
                        width: 200,
                        marginLeft: isSmallDevice ? "0px" : "290px",
                      }}
                    />
                  )}
                  <Typography variant="h4" align="flex-start">
                  Wheelを回して猫を獲得しよう！
                  </Typography>
                  <Typography
                    variant="h5"
                    align="flex-start"
                    sx={{ mt: 2, mb: 2 }}
                  >
                    スコティッシュフォールド、マンチカン、アメリカンショートヘア、ノルウェージャンフォレストキャットなど世界中に実際にいる猫をモチーフにしたキャラクターが勢ぞろい！
その数はなんと195匹！
                  </Typography>
                  <Box
                    component="img"
                    src={nWheel}
                    alt="graph"
                    sx={{
                      height: 200,
                      width: 200,
                      marginLeft: isSmallDevice ? "0px" : "290px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Box sx={item}>
                  {/* <Box
                  component="img"
                  src={nWheel}
                  alt="graph"
                  sx={{ height: 200, width: 200}}
                /> */}
                  <Box
                    component="img"
                    src={SignIn}
                    alt="graph"
                    sx={{ maxHeight: 400 }}

                    //sx={{ height: 400, width: 350 }}
                  />
                </Box>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Box>
    </>
  );
}

export default Testomonial;
