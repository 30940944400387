import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "../atom/Typography";

import DayNightImg from "../../assets/DailyCoin.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DailyCoinMobile from "../../assets/DailyCoinMobile.png";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  px: 5,
  mt:3,
  mb:1
};

function DailyCoin() {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {isSmallDevice ? (
        <>
          <Box
            component="section"
            sx={{
              display: "flex",
              overflow: "hidden",
              backgroundColor: isSmallDevice ? "#30881d" : "#6DB44A",
            }}
          >
            <img
              alt="img"
              src={DailyCoinMobile}
              style={{ width: "100%", height: "500" }}
            />
          </Box>
          <Box
            component="section"
            sx={{
              display: "flex",
              overflow: "hidden",
              backgroundColor: isSmallDevice ? "#30881d" : "#6DB44A",
            }}
          >

          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
            <Box sx={item}>
            <Typography variant="h4" align="flex-start" color='white'>
            サインアップ・サインインでボーナスコインをプレゼント！
                </Typography>
                <Typography
                  variant="h5"
                  align="flex-start"
                  sx={{ mt: 2, mb: 2 }}
                  color='white'
                >
サインアップすれば、猫やコインの履歴を残したままモバイルやタブレットなど好きなデバイスでクイズを遊ぶことができます。
                </Typography>
              </Box>
            </Grid>

          </Grid>
          </Box>
        </>
      ) : (
        <Box
          component="section"
          sx={{
            display: "flex",
            overflow: "hidden",
            backgroundColor: "#6DB44A",
          }}
        >
          <img
            alt="img"
            src={DayNightImg}
            style={{ width: "100%", height: "500" }}
          />
        </Box>
      )}
    </>
  );
}

export default DailyCoin;
