import React, { useEffect, useState } from "react";
import ProductHeroLayout from "./ProductHeroLayout";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "../atom/Typography";

import Collapse from "@mui/material/Collapse";
import Link from "@mui/material/Link";

// const backgroundImage =
//   'https://image.shutterstock.com/image-vector/programming-code-coding-hacker-background-260nw-1714491562.jpg';
import backgroundImage from "../../assets/slider.png";
import playstore from "../../assets/playstore.png";
import appstore from "../../assets/appstore.png";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  //px: 5,
};
const item2 = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  mt: 11,
  mb: 5,
  //px: 5,
};
export default function ProductHero() {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(true);
  }, []);
  const theme = useTheme()
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <ProductHeroLayout
      isHome={true}
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <Box sx={item}>
              <Collapse
                in={checked}
                {...(checked ? { timeout: 1000 } : {})}
                collapsedHeight={50}
              >
                <Typography
                  variant="h4"
                  align="flex-start"
                  style={{ color: "black",'font-size':isSmallDevice ? '15px': '35px'}}
                  //sx={{mt:17}}
                >
                  猫集めクイズゲームアプリ『はてにゃ？』
                </Typography>
              </Collapse>
            </Box>
            <Box sx={item2}>
              <Collapse
                in={checked}
                {...(checked ? { timeout: 1000 } : {})}
                collapsedHeight={50}
              >
                <Link href="https://play.google.com/store/apps/details?id=com.jbcoder.hatenya">
                  <Box
                    component="img"
                    src={playstore}
                    alt="suitcase"
                    sx={{ height: 55 }}
                  />
                </Link>

                <Link href="https://apps.apple.com/us/app/%E3%81%AF%E3%81%A6%E3%81%AB%E3%82%83-%E4%B8%96%E7%95%8C%E4%B8%AD%E3%81%AE%E7%8C%AB%E3%82%92%E9%9B%86%E3%82%81%E3%82%8B%E9%9B%91%E5%AD%A6%E3%82%AF%E3%82%A4%E3%82%BA%E3%82%B2%E3%83%BC%E3%83%A0%E3%82%A2%E3%83%97%E3%83%AA/id1671360928">
                  <Box
                    component="img"
                    src={appstore}
                    alt="suitcase"
                    sx={isSmallDevice ? { height: 55,width:182 } : { height: 55 }}
                  />
                </Link>
              </Collapse>
            </Box>
          </Grid>

          {/* <Grid item xs={12} md={5} lg={4}></Grid> */}
        </Grid>
      </div>
    </ProductHeroLayout>
  );
}
