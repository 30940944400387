import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "../components/atom/Button";
import Typography from "../components/atom/Typography";
import TextField from "@mui/material/TextField";
import emailImg from "../assets/email.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  px: 5,
};

function Contact() {
  const form = useRef();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_hxjl3vs",
        "template_gbls9du",
        form.current,
        "CVA97pm3-fv4p33rP"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Box
      component="section"
      sx={{ display: "flex", bgcolor: "white", overflow: "hidden" }}
    >
      <Container
        sx={{
          mt: 5,
          mb: 5,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: "none",
            position: "absolute",
            top: -180,
            opacity: 0.7,
          }}
        />
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12} md={7} lg={7}>
              <Box sx={item}>
                <Typography variant="h4" align="flex-start">
                  お問い合わせ
                </Typography>
                <Box
                  component="img"
                  src={emailImg}
                  alt="graph"
                  sx={{ maxHeight: isSmallDevice ? 200 : 360, mt: 5 }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={5} lg={5}>
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  noValidate
                  sx={isSmallDevice ? { mt: 2, ml: 2, mr: 2 } : { mt: 2 }}
                >
                  <Typography
                    variant="h5"
                    align="flex-start"
                    sx={{ mt: 2, mb: 1 }}
                  >
                    以下の項目を記述の上、ご送信ください。
                  </Typography>

                  <form ref={form} onSubmit={sendEmail}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          autoComplete="given-name"
                          name="user_name"
                          required
                          fullWidth
                          id="firstName"
                          label="お名前"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="email"
                          label="Email アドレス"
                          name="user_email"
                          autoComplete="email"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="outlined-multiline-static"
                          label="メッセージ"
                          name="message"
                          multiline
                          rows={3}
                          defaultValue=""
                        />
                      </Grid>
                    </Grid>
                    {/* <Link
                    href="https://www.linkedin.com/company/crack-platoon/"
                    title="Creative Commons BY 3.0"
                    target="_blank"
                    rel="noopener noreferrer"
                  > */}
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      送信
                    </Button>
                  </form>
                  <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={handleClose}
                      severity="success"
                      sx={{ width: "100%" }}
                    >
                      Message Send Successfully
                    </Alert>
                  </Snackbar>

                  {/* </Link> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  );
}

export default Contact;
